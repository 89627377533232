<template>
  <div class="page">
    <van-nav-bar
      title="股权证书"
      left-arrow
      fixed
      :border="false"
      safe-area-inset-top
      @click-left="onClickLeft"
      class="navBar"
    />
    <div class="header">
      <div class="title">股权证书</div>
      <div class="title-en">CERTIFICATE OF AUTHORIZTION</div>
      <div class="des">(请仔细阅读核对交易对方与授权证书信息是否完全一致)</div>
    </div>
    <div class="middle">
      <div class="name">[{{ user.username }}]</div>
      <!-- <div class="mobile">身份证号：{{ user.idCard }}</div>
      <div class="mobile">电话：{{ user.mobile }}</div>
      <div class="mobile">所购股权名称：{{ item.productTitle }}</div> -->
      <!-- <div class="mobile">股权数量：{{ item.num }}</div> -->

      <div class="mobile">
        <span>身份证号：</span>
        <span>{{ user.idCard }}</span>
      </div>
      <div class="mobile">
        <span>电话：</span>
        <span>{{ user.mobile }}</span>
      </div>
      <div class="mobile">
        <span>所购股权名称：</span>
        <span>{{ item.productTitle }}</span>
      </div>
      <div class="mobile">
        <span>股权数量：</span>
        <span>{{ item.num }}</span>
      </div>
      <div class="text">为华数云基金会旗下“股东”</div>
      <div class="name"></div>
      <!-- 姓名、身份证号、电话、所购股权名称、股权数量、 -->
    </div>
    <div class="footer">
      <div class="company">授权企业：<span>华数云基金会</span></div>
      <div class="time">
        授权时间：<span>{{ item.createTime.split(' ')[0] }}</span>
      </div>
      <div class="notice">
        <p>注意：本授权书以正本为有效文本不得影印、涂改、转让。</p>
        <p>基金会拥有此授权书最终解释权。</p>
        <p>无授权经营视为侵权行为、责任必究！</p>
      </div>
      <div class="official-seal">
       <img src="../../assets/images/my/product/official-seal.png" /> 
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/api.js'
export default {
  data() {
    return {
      user: {},
      item: {}
    }
  },
  created() {
    this.getUser()
    this.item = JSON.parse(localStorage.getItem('product'))
    console.log(this.item)
  },
  methods: {
    getUser() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.user = res.data.user
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  color: #770e02;
  font-weight: 550;
  font-size: 24px;
  .header {
    text-align: center;
    height: 30vh;
    background: url('../../assets/images/my/product/header.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 120px;
    box-sizing: border-box;
    .title-en {
      font-size: 14px;
      margin: 5px 0;
      font-weight: 500;
    }
    .des {
      font-weight: 500;
      font-size: 12px;
    }
  }
  .middle {
    text-align: center;
    font-size: 18px;
    height: 45vh;
    background: url('../../assets/images/my/product/middle.png') no-repeat;
    background-size: 100% 100%;
    .mobile {
      margin: 10px 0;
      font-size: 16px;
      span {
        display: inline-block;
        width: 60%;
      }
      span:first-child {
        text-align: right;
        width: 40%;
      }
      span:last-child {
        text-align: left;
      }
    }
    .text {
      margin-top: 80px;
      font-size: 20px;
    }
  }
  .footer {
    font-size: 14px;
    padding: 30px 30px 0 30px;
    height: 25vh;
    background: url('../../assets/images/my/product/footer.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .company,
    .time {
      text-align: right;
      span {
        font-size: 16px;
      }
    }
    .notice {
      margin-top: 30px;
      font-size: 12px;
      text-align: left;
      font-weight: 500;
      p {
        margin: 0;
      }
      p:not(:first-child) {
        text-indent: 36px;
      }
    }
    .official-seal {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      right: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.navBar {
  background-color: rgba(0, 0, 0, 0.2);
  ::v-deep {
    .van-nav-bar__title,
    .van-icon {
      color: #fff;
    }
  }
}
</style>
